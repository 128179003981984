import React from "react";
import Gallery from "../../components/gallery";

const Portfolio = () => {
  return (
    <div>
      <Gallery />
    </div>
  );
};

export default Portfolio;
