import React from "react";
import AboutMeWrapper from "../../components/aboutMeWrapper";

const AboutMe = () => {
  return (
    <div>
      <AboutMeWrapper />
    </div>
  );
};

export default AboutMe;
